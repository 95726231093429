body {
  background-color: var(--blue);
}

h3, h4 {
  max-width: 450px;
  text-align: center;
}

h4 {
  margin-bottom: -0.5em;
}

.panel-right, .panel-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 61vw;
  position: relative;
  left: 39vw;
  background-color: var(--white);
  min-height: 100vh;

  &.panel-center {
    left: 10vw;
    width: 80vw;
  }

  .neurocreate-icon {
    width: 11em;
    height: auto;
    margin: 2em 0;
  }

  h1 {
    font-size: 24px;
    margin: 0;
  }

  .back {
    color: #7B7B7B;
    font-size: 14px;
  }

  p {
    text-align: center;
    font-size: 14px;
    max-width: 240px;

    &.wide {
      max-width: 300px !important;
    }

    &.wider {
      max-width: 500px !important;
    }

    &.consent-notice {
      margin-top: 0.75em;
    }

    &.form-error {
      color: #b00;
      min-height: 20px;

      &.success {
        color: #080;
      }
    }

    &.device-warning {
      color: #b00;
      max-width: 400px;
      margin-bottom: 0;
    }

    &.forgotten-password {
      margin-top: 0;
      padding-top: 0;
      text-align: right;
      max-width: none;
      font-size: 12px;
      width: 95%;

      a {
        color: #4354bf;
      }
    }
  }

  div.or-divider {
    display: flex;
    width: 100%;
    color: #888;
    font-size: 14px;
    margin-bottom: 0.5em;

    hr {
      flex: 1;
      margin: 0.5em;
      background-color: #aaa;
      border: 0;
      height: 1px;
      margin-top: auto;
    }
  }

  form {
    font-size: var(--font-size-default);
    padding: 0.5em 0 1.5em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input, textarea {
      width: 27.2em;
      padding: 0.75em 1.2em;
      border: 1px solid var(--grey6);
      box-sizing: border-box;
      border-radius: .7em;
      margin: .7em 0;
      color: var(--grey1);
      letter-spacing: 0.5px;
    }
    input {
      height: 3.5em;
    }

    .button {
      width: 100%;
      box-sizing: border-box;
      font-size: var(--font-size-medium);
      border: 1px solid var(--grey6);
      text-decoration: none;
      text-align: center;
      margin: 0.4em 0;
      padding: 0.9em 0;
      border-radius: 0.5em;
      color: var(--grey6);
      font-weight: 500;
      cursor: pointer;
      max-width: 380px;

      &.login-button {
        background-color: var(--blue);
        color: var(--white);
        border: none;
      }
    }
  }
}

@media all and (min-width : 401px) and (max-width : 1024px) {
  .panel-right, .panel-center {
    width: calc(100vw - 80px);
    left: 80px;

    &.panel-center {
      width: 100vw;
      left: 0;
    }

    form {
      input, textarea {
        width: 22em
      }
      input {
        height: 3.2em;
      }

      .button {
        padding: 0.7em 0;
      }
    }
  }
}

@media all and (max-width : 400px) {
  .panel-right, .panel-center {
    width: 100vw;
    left: 0;
    top: 10px;
    bottom: 12px;
    height: calc(100vh - 24px);

    &.panel-center {
      width: 100vw;
      left: 0;
    }

    form {
      input, textarea {
        width: 22em
      }
      input {
        height: 3.2em;
      }

      .button {
        padding: 0.7em 0;
      }
    }
  }
}
